/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Switch, Text, Flex, Box } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { ComediansGrid } from '../components/ComediansGrid';
import { PageStyles } from '.';
import { AbsoluteHeaderStyles } from '../components/AdviceGrid';
import { ComediansSortByHeader } from '../components/ComediansSortByHeader';
import {
  GenericHeader,
  GenericHeaderStyles,
} from '../components/GenericHeader';
import { ComediansTrending } from '../components/ComediansTrending';

export const ComediansCategoryHeaderStyles = styled.div`
  position: relative;
  @media (max-width: 840px) {
    margin-bottom: -2rem;
  }
  @media (max-width: 640px) {
    margin-top: 10px;
  }
  @media (max-width: 414px) {
    margin-bottom: 0;
  }
`;

export default function ComediansPage({ data }) {
  const [sortByPopular, setSortByPopular] = useState(false);
  let comedians = data.comedians.nodes;
  const mostPopularAllTimeComedians = [...comedians].sort((a, b) =>
    a.viewsAllTime > b.viewsAllTime
      ? -1
      : a.viewsAllTime < b.viewsAllTime
      ? 1
      : 0
  );
  const comediansTrendingThisWeek = [...comedians].sort((a, b) =>
    a.viewsThisWeek > b.viewsThisWeek
      ? -1
      : a.viewsThisWeek < b.viewsThisWeek
      ? 1
      : 0
  );
  const snlAlumni = [...comedians].filter((c) => {
    const categoriesArr = c.categories.map((cat) => cat.category);
    if (categoriesArr.includes('SNL alumni')) {
      return c;
    }
  });
  const podcasters = [...comedians].filter((c) => {
    const categoriesArr = c.categories.map((cat) => cat.category);
    if (categoriesArr.includes('Podcaster')) {
      return c;
    }
  });
  const onJre = [...comedians].filter((c) => {
    const categoriesArr = c.categories.map((cat) => cat.category);
    if (categoriesArr.includes('Joe Rogan Experience alumni')) {
      return c;
    }
  });
  if (sortByPopular) {
    comedians = [...comedians].sort((a, b) =>
      a.viewsAllTime > b.viewsAllTime
        ? -1
        : a.viewsAllTime < b.viewsAllTime
        ? 1
        : 0
    );
  } else {
    comedians = [...comedians].sort((a, b) =>
      a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0
    );
  }
  return (
    <>
      <SEO title="Comedians | Yuks" />
      <PageStyles>
        <ComediansCategoryHeaderStyles>
          <AbsoluteHeaderStyles>
            <ComediansSortByHeader noUnderline />
          </AbsoluteHeaderStyles>
        </ComediansCategoryHeaderStyles>
        <Box>
          <GenericHeader header="Trending this week" />
          <ComediansTrending comedians={comediansTrendingThisWeek} />
        </Box>
        <Box mt="20px">
          <GenericHeader header="Most popular all-time" />
          <ComediansTrending comedians={mostPopularAllTimeComedians} />
        </Box>
        <Box mt="20px">
          <GenericHeader
            header="SNL alumni"
            href="/comedians/category/snl-alumni"
          />
          <ComediansTrending comedians={snlAlumni} />
        </Box>
        <Box mt="20px">
          <GenericHeader
            header="Podcasters"
            href="/comedians/category/podcaster"
          />
          <ComediansTrending comedians={podcasters} />
        </Box>
        <Box mt="20px">
          <GenericHeader
            header="On the Joe Rogan Experience"
            href="/comedians/category/joe-rogan-experience-alumni"
          />
          <ComediansTrending comedians={onJre} />
        </Box>
        {/* <GenericHeaderStyles>
          <Text className="header">Comedians</Text>
          <Flex paddingRight="3px" justifySelf="end" alignItems="center">
            <Text
              mr="5px"
              className="switch-text"
              id={sortByPopular ? 'selected' : ''}
              onClick={() => setSortByPopular(true)}
            >
              Popular
            </Text>
            <Switch
              colorScheme="blackAlpha"
              transform="rotate(180deg)"
              isChecked={sortByPopular}
              size="sm"
              mb="-4.5px"
              mr="5px"
              onChange={() => setSortByPopular(!sortByPopular)}
            />
            <Text
              className="switch-text"
              id={!sortByPopular ? 'selected' : ''}
              onClick={() => setSortByPopular(false)}
            >
              A-Z
            </Text>
          </Flex>
        </GenericHeaderStyles>
        <ComediansGrid comedians={comedians} standalonePage /> */}
      </PageStyles>
    </>
  );
}

export const query = graphql`
  query {
    comedians: allSanityComedian(sort: { fields: viewsAllTime, order: DESC }) {
      nodes {
        id
        firstName
        lastName
        slug {
          current
        }
        avatar {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        categories {
          id
          category
          slug {
            current
          }
        }
        viewsThisWeek
        viewsAllTime
      }
    }
  }
`;
